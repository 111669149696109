import { vibrateDevice } from 'utils/vibrate-device';

const css = 'forecast-table-days__content';

export default class ForecastTableHeader {
  constructor(td, day) {
    this.root = td;
    this.day = day;
    this.maxDetail = 0;

    this.content = td.querySelector(`.${css}`);

    this.expand = td.querySelector('button');
    if (!this.expand || this.expand.dataset.isLocked !== undefined) return;

    this.maxDetail = td.dataset.hasHPeriod !== undefined ? 2 : 1;

    this.expand.addEventListener('click', e => {
      e.preventDefault();
      vibrateDevice(50);
      this.expand.classList.add('is-loading');
      this.day
        .expand()
        .finally(() => this.expand.classList.remove('is-loading'));
    });

    this.collapse = this.expand.cloneNode(true);
    this.collapse.classList.add('is-collapse', 'is-hidden');
    this.collapse.querySelector('use').href.baseVal = '#icon-shrink';
    const div = this.root.firstElementChild;
    div.insertBefore(this.collapse, this.expand);
    this.collapse.addEventListener('click', e => {
      e.preventDefault();
      vibrateDevice(50);
      this.day.collapse();
    });
  }

  update(detail, size, cell) {
    const { minDetail } = this.day;
    const expanded = detail > minDetail;

    this.root.colSpan = size;
    this.root.classList.toggle('is-expanded-t', expanded && detail === 1);
    this.root.classList.toggle('is-expanded-h', expanded && detail === 2);

    if (this.expand) {
      this.expand.classList.toggle('is-expand', expanded);
      this.expand.classList.toggle('is-hidden', detail >= this.maxDetail);
      this.expand.firstChild.classList.toggle('is-centered', detail === 0 && size === 1);
    }

    if (this.collapse) {
      this.collapse.classList.toggle('is-hidden', detail <= minDetail);
    }

    const content = cell.querySelector(`.${css}`).cloneNode(true);
    this.content.replaceWith(content);
    this.content = content;
  }
}
