import { fetchData } from 'utils/fetch-data';
import airbrake from 'utils/airbrake';
import { tableSections } from 'utils/table-sections';
import parseHtml from 'utils/parse-html';
import { TempValue } from 'components/temp-value';
import { getCells } from './freezing-graph/cells'
import { getPoints } from './freezing-graph/polyline'
import { dayCellsDate } from './cells';

export const KEY_DAY = 'days';
export const KEY_TEMP_MIN = 'temperature-min';
export const KEY_GRAPH = 'freezing-graph';

export default class ForecastTableData {
  constructor(url, elevs, defaultElev) {
    this.url = url;
    this.elevs = elevs;
    this.defaultElev = defaultElev;
    this.elevPromise = {};
    this.data = {};
  }

  // eslint-disable-next-line class-methods-use-this
  parse(table) {
    const cells = tableSections(table);
    if (!cells[0].time) return { cells: null };

    let graph = cells[0][KEY_GRAPH];

    for (let i = 0; i < cells.length; i++) {
      delete cells[i].full;
      delete cells[i][KEY_GRAPH];
    }

    if (graph && graph.length) {
      [graph] = graph;
      TempValue.init(graph);
      const elevs = getCells(graph);
      const curveLines = [...graph.querySelectorAll('.curve-line')];
      const points = curveLines.map(l => getPoints(l));

      let start = 0;
      for (let i = 0; i < cells.length; i++) {
        const dayCells = cells[i];
        const size = dayCells.time.length;
        const end = start + size;
        dayCells[KEY_GRAPH] = [
          elevs.map(elevCells => elevCells.slice(start, end)),
          points.map(linePoints => linePoints.slice(start, end))
        ];
        start += size;
      }
    }

    return { cells, graph };
  }

  async fetchExpansion(elev, detail) {
    let key = 'p';
    if (detail === 1) key = 't';
    else if (detail === 2) key = 'h';

    if (!this.data[elev] || !this.data[elev][key]) await this.fetchElevation(elev);

    return this.data[elev][key];
  }

  async fetchDay(elev, detail, date) {
    const cells = await this.fetchExpansion(elev, detail);
    return cells.find(dayCells => dayCellsDate(dayCells) === date);
  }

  async fetchPast(elev, date, complete) {
    let url = `${this.url}?elev=${elev}&past_date=${date}`;
    if (complete) url += '&complete=1';

    const data = await fetchData(url);
    if (!data.table) return data;

    const doc = parseHtml(data.table);
    const [table] = doc.body.childNodes;
    const { cells } = this.parse(table);
    delete data.table;
    return { cells, ...data };
  }

  fetchElevation(elev) {
    if (!this.elevPromise[elev]) {
      this.elevPromise[elev] = this.loadElevation(elev);
    }

    return this.elevPromise[elev];
  }

  async loadElevation(elev) {
    let { url } = this;
    url += `?elev=${elev}`;

    const periods = ['t', 'h'];
    if (elev !== this.defaultElev) periods.unshift('p');
    url += `&period_types=${periods.join(',')}`;

    try {
      const data = await fetchData(url);
      if (!this.data[elev]) this.data[elev] = {};

      periods.forEach(p => {
        const pdata = data.elevations[elev].period_types[p];
        if (!pdata) return;

        const doc = parseHtml(pdata.table);
        const [scroller] = doc.body.childNodes;
        const [table] = scroller.childNodes;
        const { cells } = this.parse(table);
        this.data[elev][p] = cells;
      });
    } catch (e) {
      airbrake.notify(e);
    }
  }
}
