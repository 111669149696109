import { WindIcon } from 'components/wind-icon';
import initSwellIcons from 'components/swell-icon';
import { SnowAmount } from 'components/snow-amount';
import { RainAmount } from 'components/rain-amount';
import { TempValue } from 'components/temp-value';
import { TideTime } from 'components/tide-time';
import { LevelValue } from './level-value';
import airbrake from 'utils/airbrake';
import units from 'utils/units';
import ForecastTableHeader from './header';
import {
  KEY_DAY,
  KEY_TEMP_MIN,
  KEY_GRAPH
} from './data';

function insertRowBefore(cells, name, before) {
  let debug = false;
  try {
    if (localStorage.getItem('fc-debug')) debug = true;
  } catch(e) {}

  const parent = before.parentNode;
  if (debug) {
    console.log(cells);
    console.log(name);
  }
  cells[name].forEach(td => parent.insertBefore(td, before));
}

export default class ForecastTableDay {
  constructor({ table, date, minDetail, summaryCell }) {
    this.table = table;
    this.date = date;
    this.minDetail = minDetail;
    this.summaryCell = summaryCell;
    this.cells = {};
    this.loading = false;
  }

  size() {
    return this.cells[this.level][this.detail].time.length;
  }

  isIncomplete() {
    const cs = this.cells[this.level];
    return !cs[0] || !cs[0].time || cs[0].time.length < 3;
  }

  expand() {
    const detail = Math.min(this.detail + 1, this.header.maxDetail);
    return this.changeDetail(detail);
  }

  collapse() {
    const detail = Math.max(this.minDetail, this.detail - 1);
    return this.changeDetail(detail);
  }

  insert(level, detail, cells, beforeDay) {
    this.setCells(level, detail, cells);
    this.insertCells(cells, beforeDay);
  }

  async changeDetail(detail) {
    if (detail === this.detail || this.loading) return;

    this.loading = true;

    try {
      if (!this.cells[this.level][detail]) {
        const cells = await this.table.fetchExpansion(this.level, this.date, detail);
        this.setCells(this.level, detail, cells);
      }

      if (!this.cells[this.level][detail]) {
        throw new Error(`No expansion data for ${this.date} at detail ${detail}`);
      }

      this.updateCells(detail, this.cells[this.level][detail]);
    } catch (e) {
      airbrake.notify(e);
    }

    this.loading = false;
  }

  async changeLevel(level) {
    if (level == this.level || this.loading) return;

    this.loading = true;

    try {
      if (!this.cells[level]) this.cells[level] = [];

      if (!this.cells[level][this.detail]) {
        let cells = await this.table.fetchExpansion(level, this.date, this.detail);
        if (!cells) {
          cells = {};
          Object.keys(this.refCells).forEach(name => {
            cells[name] = [];
          });
        }
        this.setCells(level, this.detail, cells);
      }

      this.updateCells(this.detail, this.cells[level][this.detail]);
      this.level = level;
    } catch (e) {
      airbrake.notify(e);
    }

    this.loading = false;
  }

  setCells(level, detail, cells) {
    if (!cells) return;

    for (const key in cells) {
      if (key == KEY_GRAPH) continue;

      for (const td of cells[key]) {
        WindIcon.init(td);
        initSwellIcons(td, units);
        SnowAmount.init(td);
        RainAmount.init(td);
        TempValue.init(td);
        TideTime.init(td);
        LevelValue.init(td);
      }
    }

    if (!this.cells[level]) this.cells[level] = [];
    this.cells[level][detail] = cells;

    let css;
    if (detail === 2) css = 'is-expanded-h';
    if (detail === 1) css = 'is-expanded-t';

    if (css && detail > this.minDetail) {
      Object.keys(cells).forEach(k => {
        if (k === KEY_GRAPH) return;
        if (!Object.hasOwnProperty.call(cells, k)) return;

        cells[k].forEach(cell => cell.classList.add(css));
      });
    }

    if (this.level === undefined) {
      this.level = level;
    }

    if (this.detail === undefined) {
      this.refCells = this.cells[level][detail];
      this.detail = detail;
      const header = cells.days[0];
      this.header = new ForecastTableHeader(header, this);
    }
  }

  insertCells(cells, beforeDay) {
    if (!cells) return;

    const newSize = cells.time.length;

    Object.keys(cells).forEach(name => {
      if (name === KEY_GRAPH) return;
      if (!Object.hasOwnProperty.call(cells, name)) return;

      const before = beforeDay.firstCell(name);
      insertRowBefore(cells, name, before);
    });

    const freezingData = cells[KEY_GRAPH];
    this.table.detailChanged(this, 0, newSize, freezingData);
  }

  updateCells(detail, cells) {
    if (!cells) return;

    const oldSize = this.cells[this.level][this.detail].time.length;
    const newSize = cells.time.length;

    Object.keys(this.refCells).forEach(name => {
      this.updateRow(cells, name, detail, newSize);
    });

    if (this.summaryCell) {
      this.summaryCell.colSpan += newSize - oldSize;
    }

    const freezingData = cells[KEY_GRAPH];
    this.table.detailChanged(this, oldSize, newSize, freezingData);

    this.detail = detail;
  }

  updateRow(cells, name, detail, size) {
    if (name === KEY_GRAPH) return;

    if (name === KEY_DAY) {
      if (cells[name][0]) {
        this.header.update(detail, size, cells[name][0]);
      } else {
        this.removeRow(name);
      }
    } else if (name === KEY_TEMP_MIN) {
      this.updateMinTemp(cells, name, detail);
    } else {
      const firstCell = this.firstCell(name);
      insertRowBefore(cells, name, firstCell);
      this.removeRow(name);
    }
  }

  firstCell(name) {
    if (name === KEY_DAY) return this.header.root;
    if (name === KEY_TEMP_MIN && this.detail > 0) return this.stubMinTemp;
    return this.cells[this.level][this.detail][name][0];
  }

  updateMinTemp(cells, name, detail) {
    if (cells[name]) {
      if (this.cells[this.level][this.detail][name]) {
        const firstCell = this.firstCell(name);
        insertRowBefore(cells, name, firstCell);
        this.removeRow(name);
      } else if (this.stubMinTemp) {
        insertRowBefore(cells, name, this.stubMinTemp);
        this.stubMinTemp.remove();
      }
    } else {
      if (!this.stubMinTemp) {
        const row = this.refCells[name];
        const lastCell = row[row.length - 1];
        const stubCell = lastCell.cloneNode(true);
        stubCell.querySelector('div').innerText = '—';
        this.stubMinTemp = stubCell;
      }

      this.stubMinTemp.colSpan = cells.time.length;
      this.stubMinTemp.classList.toggle('is-expanded-t', detail === 1);
      this.stubMinTemp.classList.toggle('is-expanded-h', detail === 2);

      if (this.cells[this.level][this.detail][name]) {
        const firstCell = this.firstCell(name);
        firstCell.parentNode.insertBefore(this.stubMinTemp, firstCell);
        this.removeRow(name);
      }
    }
  }

  removeRow(name) {
    this.cells[this.level][this.detail][name].forEach(td => td.remove());
  }
}
